// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useRef, useEffect} from "react";
import { PlasmicDebtorDetails } from "./plasmic/collect_wise_draft/PlasmicDebtorDetails";
import CurrencyInput from "react-currency-input-field";
import "../Input.css"
import { db, storage } from "../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit, updateDoc } from "firebase/firestore";
import { v4 } from "uuid";
import { ref, uploadBytes, getDownloadURL, uploadBytesResumable, listAll, list, } from "firebase/storage";
import { useAuth } from "../contexts/AuthContext";
import LoadingSpinner from "./LoadingSpinner";
import Moment from 'moment';
import { useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import { config } from './constants'
import DebtorContact from './DebtorContact'
import FileUploader from "./CreationFileUploader";
import render from 'react-dom'


function DebtorDetails_(props, ref1) {

  const [debtorName, setDebtorName] = useState('')
  const [debtAge, setDebtAge] = useState('')
  const [address1, setAddress1] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [creditorName, setCreditorName] = useState('')
  const [merchantName, setMerchantName] = useState('')
  const [debtSituation, setDebtSituation] = useState('')
  const [error, setError] = useState('')
  const [debtorNameError, setDebtorNameError] = useState('')
  const [productNameError, setProductNameError] = useState('')
  const [debtAmountError, setDebtAmountError] = useState('')
  const [debtAgeError, setDebtAgeError] = useState('')
  const [debtorPhoneError, setDebtorPhoneError] = useState('')
  const [debtorEmailError, setDebtorEmailError] = useState('')
  const [uploadContractFile, setUploadContractFile] = useState(null)
  const [uploadInvoiceFile, setUploadInvoiceFile] = useState(null)
  const [uploadOtherFile, setUploadOtherFile] = useState(null)
  const { currentUser, contactFields, setContactFields } = useAuth()
  const [loading, setLoading] = useState(false)
  const [productName, setProductName] = useState('')
  const [creditorNameError, setCreditorNameError] = useState('')
  const [userInfoDocId, setUserInfoDocId] = useState('')
  const [contactNameError, setContactNameError] = useState('')
  const [contactPhoneEmailError, setContactPhoneEmailError] = useState('')
  const [autoEnrollDebtors, setAutoEnrollDebtors] = useState(false)
  const [collectionsEmail, setCollectionsEmail] = useState("")
  const [customWorkflowID, setCustomWorkflowID] = useState("")
  const [files, setFiles] = useState([])

  const formatDate = Moment().format("MMM Do, YYYY");
  const history = useHistory()

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };


  // write a function that converts a full name to lowercase and then capitalizes the first letter of each word



  function formatName(name) {
    return name
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}


  const currencyInput = useRef(null)

  async function uploadFile(upload) {

    if (upload == null) return '';

    const path = `debtorFile/${currentUser.uid}/${upload.name + v4()}`

    const imageRef = ref(storage, path);

    uploadBytes(imageRef, upload).then((snapshot) => {


    });

    return path

  }
  useEffect(() => {
  
    console.log("Files in state", files)

  }, [files])



  useEffect(async () => {
  
    const q = query(
      collection(db, "userInfo"),
      where("user", "==", currentUser.uid)
    );
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      setCreditorName(doc.data().merchantName)
      setMerchantName(doc.data().merchantName)
      setUserInfoDocId(doc.id)
      setAutoEnrollDebtors(doc.data().autoEnrollDebtors ? doc.data().autoEnrollDebtors : false)
      setCollectionsEmail(doc.data().collectionsEmail ? doc.data().collectionsEmail : "")
      setCustomWorkflowID(doc.data().customWorkflowID ? doc.data().customWorkflowID : "")
    })

  }, [])



  return loading ? <PlasmicDebtorDetails root={{ ref1 }} {...props} vertStack={{children: <LoadingSpinner/>}} /> : <PlasmicDebtorDetails root={{ ref1 }} {...props}
  debtorName={{
    value: debtorName,
    onChange: (e) => {
      setDebtorName(e.target.value)
    }
  }}
  debtAmount={{
    children: <CurrencyInput className="input"
    prefix="$"
    name="currencyInput"
    id="currencyInput"
    data-number-to-fixed="2"
    data-number-stepfactor="100"
    // value={value}
    placeholder="$ "
    // onChange={handleChange}
    // onBlur={handleOnBlur}
    allowDecimals
    decimalsLimit="2"
    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
    disableAbbreviations
    ref={currencyInput}
  />
  }}
  // debtAge={{
  //   value: debtAge,
  //   onChange: (e) => {
  //     setDebtAge(e.target.value)
  //   }
  // }}
  debtAge={{
    children: <DatePicker className="dateBox2" selected={debtAge} onChange={(date) => setDebtAge(date)} />
  }}
  address1={{
    value: address1,
    onChange: (e) => {
      setAddress1(e.target.value)
    }
  }}
  city={{
    value: city,
    onChange: (e) => {
      setCity(e.target.value)
    }
  }}
  state={{
    value: state,
    onChange: (e) => {
      setState(e)
    }
  }}
  zip={{
    value: zip,
    onChange: (e) => {
      setZip(e.target.value)
    }
  }}
  creditorName={{
    value: creditorName,
    onChange: (e) => {
      setCreditorName(e.target.value)
    }
  }}
  debtSituation={{
    value: debtSituation,
    onChange: (e) => {
      setDebtSituation(e.target.value)
    }
  }}
  productName={{
    value: productName,
    onChange: (e) => {
      setProductName(e.target.value)
    }
  }}
  uploadContractFile={{
    onClick: () => {

      console.log('tried')

      var input = document.createElement('input');
      input.type = 'file';

      input.onchange = e => {
        setUploadContractFile(e.target.files[0])
      }

      input.click();

    }
  }}
  uploadInvoiceFile={{
    onClick: () => {

      var input = document.createElement('input');
      input.type = 'file';

      input.onchange = e => {
        setUploadInvoiceFile(e.target.files[0])
      }

      input.click();

    }
  }}
  uploadOtherFile={{
    onClick: () => {

      var input = document.createElement('input');
      input.type = 'file';

      input.onchange = e => {
        setUploadOtherFile(e.target.files[0])
      }

      input.click();

    }
  }}
  supportingDocuments={{
    
    children: <FileUploader setFiles={setFiles} files={files}/>,
    wrap: (node) => node
  }}
  errorText={{
    wrap: (node) => error ? node : null,
  }}
  debtorNameError={{
    wrap: (node) => debtorNameError ? node : null,
  }}
  debtAmountError={{
    wrap: (node) => debtAmountError ? node : null,
  }}
  debtAgeError={{
    wrap: (node) => debtAgeError ? node : null,
  }}
  debtorPhoneError={{
    wrap: (node) => debtorPhoneError ? node : null,
  }}
  debtorEmailError={{
    wrap: (node) => debtorEmailError ? node : null,
  }}
  productError={{
    wrap: (node) => productNameError ? node : null,
  }}
  creditorNameError={{
    wrap: (node) => creditorNameError ? node : null,
  }}
  contractFileStack={{
    wrap: (node) => uploadContractFile ? node : null,
  }}
  invoieFileStack={{
    wrap: (node) => uploadInvoiceFile ? node : null,
  }}
  otherFileStack={{
    wrap: (node) => uploadOtherFile ? node : null,
  }}
  contactStack={{
    children: contactFields.map((x, i) => {
      i += 1
      return <DebtorContact key={x.id} id={x.id} number={i + ""} contactNameError={contactNameError} contactPhoneEmailError={contactPhoneEmailError}/>
    })
  }}
  addContact={{
    onClick: () => {
      
      if ( (contactFields[contactFields.length - 1].name === "") || (contactFields[contactFields.length - 1].phone === "" && contactFields[contactFields.length - 1].email === "") ) {

      if( (contactFields[contactFields.length - 1].name === "") ) {
        setContactNameError(true)
      }
      
      if (contactFields[contactFields.length - 1].phone === "" || contactFields[contactFields.length - 1].email === "") {
        setContactPhoneEmailError(true)
      }
      
      
     } else {
        setContactNameError(false)
        setContactPhoneEmailError(false)
        setContactFields([...contactFields, {name: '', phone: '', email: '', id: v4()} ])
      }

      
  
  }
  }}
  contractFile={uploadContractFile ? uploadContractFile.name : null}
  invoiceFile={uploadInvoiceFile ? uploadInvoiceFile.name : null}
  otherFile={uploadOtherFile ? uploadOtherFile.name : null}
  submitButton={{
    onClick: async () => {
      
      console.log(contactFields)

      if (debtorName === '') {
        setDebtorNameError(true)
      } else {
        setDebtorNameError(false)
      }

      if(currencyInput.current.value === '') {
        setDebtAmountError(true)
      } else {
        setDebtAmountError(false)
      }


      if (debtAge === '') {
        setDebtAgeError(true)
      } else {
        setDebtAgeError(false)
      }

      if (productName === '') {
        setProductNameError(true)
      } else {
        setProductNameError(false)
      }

      if (creditorName === '') {
        setCreditorNameError(true)
      } else {
        setCreditorNameError(false)
      }

      if( (contactFields[contactFields.length - 1].name === "") ) {
        setContactNameError(true)
      } else{
        setContactNameError(false)
      }
      
      if (contactFields[contactFields.length - 1].phone === "" || contactFields[contactFields.length - 1].email === "") {
        setContactPhoneEmailError(true)
      } else {
        setContactPhoneEmailError(false)
      }

  

      if (debtorName === '' || currencyInput.current.value === '' || debtAge === '' || productName === '' || creditorName === '' || (contactFields[contactFields.length - 1].name === "") || (contactFields[contactFields.length - 1].phone === "" && contactFields[contactFields.length - 1].email === "") ) {
        setError(true)
      } else {
          setError(false)

          var id = v4()

          setLoading(true)

          window.addEventListener("beforeunload", handleBeforeUnload);


        var paymentId = v4()

        var paymentDataId = v4()



        var debtAmount = currencyInput.current.value

        var debtorDocId = v4()

      
        for (var i = 0; i < contactFields.length; i++){

          contactFields[i].name = formatName(contactFields[i].name)

        }

        
        const debtors = await setDoc(doc(db, "debtors", debtorDocId), { 
          user: currentUser.uid,
          debtorName: debtorName,
          debtAmount: debtAmount,
          debtAge: debtAge,
          address1: address1,
          city: city,
          state: state,
          zip: zip,
          creditorName: creditorName,
          debtSituation: debtSituation,
          files: files,
          date: serverTimestamp(),
          amountCollected: 0,
          numberOfEmails: 0,
          numberOfTexts: 0,
          numberOfCalls: 0,
          displayDate: formatDate,
          id: id,
          productName: productName,
          debtorDocId: debtorDocId,
          contactFields: contactFields
        })


        const payments = await addDoc(collection(db, "payments"), {
          // api_deck_invoiceId: response ? response.data.id : null,
          user: currentUser.uid,
          userEmail: currentUser.email,
          type: "Invoice Payment",
          amount: debtAmount,
          amountNum: Number(debtAmount.substring(1).replace(/,/g, "")),
          paymentId: paymentId,
          status: "Not Paid",
          clientCompany: debtorName,
          dateCreated: formatDate,
          paymentDataId: paymentDataId,
          datePaid: "NA",
          paymentMethod: "NA",
          paymentNotes: "",
          date: serverTimestamp(),
          // might need to fix this is if it causes an error
          clientEmail: "",
          datePaidNum: "NA",
          fundsLocation: "NA",
          paymentSuccess: false,
          invoiceNum: 1,
          invoice_identifier: '',
          merchantName: creditorName,
          invoiceFields: [{ item: productName, quantity: 1, price: debtAmount, id: v4(), balanceDue: Number(debtAmount.substring(1).replace(/,/g, "")) }],
          dueDate: new Date(),
          userInfoDocId: userInfoDocId,
          reminderOptions: { enabled: false, number: 0, unit: '' },
          automationOptions: { enabled: false, number: 0, unit: '' },
          paymentMethods: { ACH: true, Card: true, Wire: true, CardSurcharge: false },
          paymentVoided: false,
          voidDate: "NA",
          yourToken: v4(),
          // replace with your public heartland key
          heartlandPublicKey: "pkapi_prod_abGs9IFbyTNV7oA2Z9",
          plan: "Free",
          debtorDocId: debtorDocId
          // api_deck_ledger_id: userInfo.api_deck_ledger_id ? userInfo.api_deck_ledger_id : null,
          // api_deck_supplier_id: userInfo.api_deck_supplier_id ? userInfo.api_deck_supplier_id : null,
          // api_deck_bank_id: userData.api_deck_bank_id ? userData.api_deck_bank_id : null
        })

        var resp = await axios({
          method: "POST",
          url: `${config.endpoint}/crmFunctions-addDebtors`,
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${await currentUser.getIdToken()}`
          },
          data: {
            debtorName: formatName(debtorName),
            debtAmount: debtAmount,
            debtAge: Moment(debtAge).format('M-D-YYYY'),
            address1: address1,
            city: city,
            state: state,
            zip: zip,
            creditorName: creditorName,
            merchantName: merchantName,
            debtSituation: debtSituation,
            displayDate: formatDate,
            productName: productName,
            paymentLink: `https://app.collectwise.org/invoicepayments/${paymentId}`,
            contactFields: contactFields,
            autoEnrollDebtors: autoEnrollDebtors,
            userInfoDocId: userInfoDocId,
            collectionsEmail: collectionsEmail,
            debtorDocId: debtorDocId,
            isAgency: false,
            customWorkflowID: customWorkflowID
          }
        })

        history.push(`/debtor/${id}`)

        setContactFields([{name: '', phone: '', email: '', id: v4()} ])

        setLoading(false)

        window.removeEventListener("beforeunload", handleBeforeUnload);

      }

  
    }
  }}
  />;
}

const DebtorDetails = React.forwardRef(DebtorDetails_);

export default DebtorDetails;
