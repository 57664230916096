// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState, useEffect} from "react";
import { PlasmicDebtorSubmission } from "./plasmic/collect_wise_draft/PlasmicDebtorSubmission";
import { db, storage } from "../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit, startAt, endAt, startAfter, endBefore, limitToLast } from "firebase/firestore";
import createTableFlowImporter from "@tableflow/js";
import { v4 } from "uuid";
import { config } from './constants'
import Moment from 'moment';
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from 'react-router-dom';
import LoadingSpinner from "./LoadingSpinner";
import { toast } from "react-toastify";


function DebtorSubmission_(props, ref) {
  const formatDate = Moment().format("MMM Do, YYYY")
  const { currentUser, clientHits, setClientHits, openClientSearch, checkPermission, clientSubmission, setClientSubmission, showToast, debtorProgress, setDebtorProgress } = useAuth()
  const [error, setError] = useState(false)
  const [uploadDebtors, setUploadDebtors] = useState(false)
  const [webPortal, setWebPortal] = useState(false)
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)

  var debtorObjects = []

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  }


  function formatName(name) {
    return name
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
}


const handleClose = () => setShowModal(false)


async function formatNumber(number) {

var temp = 0


  if (number[0] === "$") {
    temp = Number(number.substring(1).replace(/,/g, ""))
  } else {
    temp = Number(number.replace(/,/g, ""))
  }

  var newtemp = temp.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  })

  return newtemp

}


async function handleAddDebtors(totalDebtors, debtor, creditorName, userInfoDocId, autoEnrollDebtors, collectionsEmail, user, isAgency, userEmail, customWorkflowID) { 

  var id = v4();
         
  var paymentId = v4()

  var paymentDataId = v4()

  var debtorDocId = v4()

  var debtAmountNum = 0


  if (debtor.amount_of_unpaid_debt[0] === "$") {
    debtAmountNum = Number(debtor.amount_of_unpaid_debt.substring(1).replace(/,/g, ""))
  } else {
    debtAmountNum = Number(debtor.amount_of_unpaid_debt.replace(/,/g, ""))
  }


  debtorObjects.push({
      "debtorName": formatName(debtor.debtor_name),
      "debtAmount": debtAmountNum.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
      "debtAge": debtor.age_of_the_debt,
      "address1": debtor.address ? debtor.address : "",
      "city": debtor.city ? debtor.city : "",
      "state": debtor.state ? debtor.state : "",
      "zip": debtor.zip_code ? debtor.zip_code : "",
      "creditorName": debtor.creditor_name ? debtor.creditor_name : creditorName,
      "merchantName": creditorName,
      "debtSituation": debtor.description_of_the_debt_situation ? debtor.description_of_the_debt_situation : "",
      "displayDate": formatDate,
      "productName": debtor.product_name ? debtor.product_name : "",
      "paymentLink": `https://app.collectwise.org/invoicepayments/${paymentId}`,
      "contactFields": [{"name": formatName(debtor.debtor_name), "phone": debtor.phone_number ? debtor.phone_number : "", "email": debtor.primary_email ? debtor.primary_email : "", "id": v4()} ],
      "autoEnrollDebtors": autoEnrollDebtors,
      "collectionsEmail": collectionsEmail,
      "userInfoDocId" : userInfoDocId,
      "isAgency" : isAgency,
      "debtorDocId" : debtorDocId,
      "customWorkflowID": customWorkflowID
    })



  const debtorDocument = await setDoc(doc(db, "debtors", debtorDocId), { 
    user: user,
    debtorName: debtor.debtor_name,
    client_account_number: debtor.account_number ? debtor.account_number : "",
    debtAmount: debtAmountNum.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    }),
    debtAge: debtor.age_of_the_debt ? (!isNaN(new Date(debtor.age_of_the_debt).getTime()) ? new Date(debtor.age_of_the_debt) : debtor.age_of_the_debt ) : "",
    // add multiple contacts with d1 name, d2 name, etc
    contactFields: [{name: formatName(debtor.debtor_name), phone: debtor.phone_number ? debtor.phone_number : "", email: debtor.primary_email ? debtor.primary_email : "", id: v4()} ],
    address1: debtor.address ? debtor.address : "",
    city: debtor.city ? debtor.city : "",
    state: debtor.state ? debtor.state : "",
    zip: debtor.zip_code ? debtor.zip_code : "",
    creditorName: debtor.creditor_name ? debtor.creditor_name : creditorName,
    debtSituation: debtor.description_of_the_debt_situation ? debtor.description_of_the_debt_situation : "",
    date: serverTimestamp(),
    amountCollected: 0,
    numberOfEmails: 0,
    numberOfTexts: 0,
    numberOfCalls: 0,
    displayDate: formatDate,
    id: id,
    productName: debtor.product_name ? debtor.product_name : "",
    debtorDocId: debtorDocId,
    last_pay_date: debtor.last_pay_date ? debtor.last_pay_date : "",
    charge_off_date: debtor.charge_off_date ? debtor.charge_off_date : "",
    interest_due: debtor.interest_due ? await formatNumber(debtor.interest_due) : "",
    principal_due: debtor.principal_due ? await formatNumber(debtor.principal_due) : "",
    other_fees: debtor.other_fees ? await formatNumber(debtor.other_fees) : "",
    amount_paid: debtor.amount_paid ? await formatNumber(debtor.amount_paid) : "",
    paymentLink: `https://app.collectwise.org/invoicepayments/${paymentId}`,
    isAgency : isAgency,
  })


  const payments = await addDoc(collection(db, "payments"), {
  // api_deck_invoiceId: response ? response.data.id : null,
  user: user,
  userEmail: userEmail,
  type: "Invoice Payment",
  amount: debtAmountNum.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  }),
  amountNum: debtAmountNum,
  paymentId: paymentId,
  status: "Not Paid",
  clientCompany: debtor.debtor_name,
  dateCreated: formatDate,
  paymentDataId: paymentDataId,
  datePaid: "NA",
  paymentMethod: "NA",
  paymentNotes: "",
  date: serverTimestamp(),
  clientEmail: debtor.primary_email ? debtor.primary_email : "",
  datePaidNum: "NA",
  fundsLocation: "NA",
  paymentSuccess: false,
  invoiceNum: 1,
  invoice_identifier: '',
  merchantName: debtor.creditor_name ? debtor.creditor_name : creditorName,
  invoiceFields: [{ item: debtor.product_name ? debtor.product_name : "", quantity: 1, price: debtAmountNum.toLocaleString("en-US", {style: "currency",currency: "USD", }), id: v4(), balanceDue: debtAmountNum }],
  dueDate: new Date(),
  userInfoDocId: userInfoDocId,
  reminderOptions: { enabled: false, number: 0, unit: '' },
  automationOptions: { enabled: false, number: 0, unit: '' },
  paymentMethods: { ACH: true, Card: true, Wire: true, CardSurcharge: false },
  paymentVoided: false,
  voidDate: "NA",
  yourToken: v4(),
  // replace with your public heartland key
  heartlandPublicKey: "pkapi_prod_abGs9IFbyTNV7oA2Z9",
  plan: "Free",
  debtorDocId: debtorDocId
  // api_deck_ledger_id: userInfo.api_deck_ledger_id ? userInfo.api_deck_ledger_id : null,
  // api_deck_supplier_id: userInfo.api_deck_supplier_id ? userInfo.api_deck_supplier_id : null,
  // api_deck_bank_id: userData.api_deck_bank_id ? userData.api_deck_bank_id : null
})



}


var user = currentUser.uid
var userEmail = currentUser.email

if (currentUser.email === "sean+masteruser@collectwise.org") {
  user = 'Gr8GC7dYzdfICU9kGVdBe8Ha47d2'
}


  const importer = createTableFlowImporter({
    importerId: "a53284d7-72ac-4dc7-a593-f97f6f4dc6c7", // Use your importer ID from https://app.tableflow.com/importers
    modalOnCloseTriggered: () => importer.close(),
    metadata: { user_id: user },
    onComplete: async (data, error) => {
  
      window.addEventListener("beforeunload", handleBeforeUnload);
       
        importer.close()

        var debtors = data?.rows

        history.push(`/debtorslistloading?amount=${debtors.length}`)
        

        const q = query(
          collection(db, "userInfo"),
          where("user", "==", user)
        )

          var creditorName = ""
          var userInfoDocId = ""
          var autoEnrollDebtors = false
          var collectionsEmail = ""
          var isAgency = false
          var customWorkflowID = ""

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          creditorName = doc.data().merchantName
          userInfoDocId = doc.id
          autoEnrollDebtors = doc.data().autoEnrollDebtors ? doc.data().autoEnrollDebtors : false
          collectionsEmail = doc.data().collectionsEmail ? doc.data().collectionsEmail : ""
          isAgency = doc.data().isAgency ? doc.data().isAgency : false
          customWorkflowID = doc.data().customWorkflowID ? doc.data().customWorkflowID : ""
        })

        

        for (var i = 0; i < debtors.length; i++) {
    
            var debtor = debtors[i].values

            var totalDebtors = debtors.length

           
            if (i === (debtors.length - 1)) {

             await handleAddDebtors(totalDebtors, debtor, creditorName, userInfoDocId, autoEnrollDebtors, collectionsEmail, user, isAgency, userEmail, customWorkflowID)

             var resp = await axios({
              method: "POST",
              url: `${config.endpoint}/crmFunctions-addDebtorsBulk`,
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${await currentUser.getIdToken()}`
              },
              data: debtorObjects
            })


            } else {
              handleAddDebtors(totalDebtors, debtor, creditorName, userInfoDocId, autoEnrollDebtors, collectionsEmail, user, isAgency, userEmail, customWorkflowID)
            }

             
        }

        debtorObjects = []

        toast.success("Debtor List Uploaded", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
          })

        history.push("/debtorslist?uploadfiles")
        

        window.removeEventListener("beforeunload", handleBeforeUnload);
       

    },
    darkMode: false,
    primaryColor: "#048B68"
  });
  


  return (
  <>
  {loading ? <LoadingSpinner/> : <PlasmicDebtorSubmission root={{ ref }} {...props} 
  errorText={{
    wrap: node => error ? node : null
  }}
  uploadDebtorsButton={{
    selected: uploadDebtors,
    onClick: () => {
      setUploadDebtors(true)
      setWebPortal(false)
      setError(false)
    }
  }}
  webPortalButton={{
    selected: webPortal,
    onClick: () => {
      setWebPortal(true)
      setUploadDebtors(false)
      setError(false)
    }
  }}
  continueButton={{
    onClick: () => {

      if (!(uploadDebtors || webPortal)) {
        setError(true)
      } else {

      if (uploadDebtors) {
        importer?.showModal();
      } else {
        history.push("/debtordetails")
      }
  
      }

    }
  }}
  /> }
  </> )
}

const DebtorSubmission = React.forwardRef(DebtorSubmission_);

export default DebtorSubmission;
